<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" class="mt-5">
        <div class="mt-5">
          <h3>{{ title }}</h3>
          <h5 class="primary-color">{{ pay_tid ? "Payment Id - " + pay_tid : "" }}</h5>
          <h5 class="mt-2" v-html="message"></h5>
          <div class="sign-info">
            <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
            <ul class="iq-social-media mt-4">
              <li>
                <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { socialvue } from "../config/pluginInit"
export default {
  name: "Confirmation",
  data: () => ({
    pay_tid: "",
    title: "Thank You",
    message: `Thank you for registering on GIDE.AI. Please check your email for the One-Time Password (OTP) to Proceed. <a class="pointer primary-color" href="${process.env.VUE_APP_SERVER_URL}/activate">Click Here</a> to verify OTP.<br><br>If you don't see it, remember to check your Spam folder.<br>If you are already logged in, please refresh your browser tab<br><br>Need help? Contact <a class="pointer primary-color" href="mailto:support@gide.ai">support@gide.ai</a>`
  }),
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    socialvue.index()
    let code = this.$route.query.code ? this.$route.query.code : ""
    let payTid = this.$route.query.pay_tid ? this.$route.query.pay_tid : ""

    switch (code) {
      case "REGISTERATION_DONE":
        break

      case "PAYMENT_DONE":
        this.title = "Payment Received"
        this.pay_tid = payTid
        break

      default:
    }
  },
  methods: {
  }
}
</script>
